.description__container {
  text-align: center;
}

.description__section {
  margin: 2rem;
}

.button__container {
  padding-top: 2rem;
  display: flex;
  justify-content: center;
}

.devicons {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

i {
  font-size: 4rem;
  padding: 1rem;
}
